import "src/components/organisms/WordPress/SingleEvent.css.tsx.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/SingleEvent.css.tsx.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE71WwXKbMBC9+yt2nOlMMhMIEENsemmbpoceMp3pFwhYY8UgMZIcO+n43zsogAXIrnNo4Lbs6u2+fbvCTV/DjBUe/JkAZFRWBXmJYVng7vMEgBQ0Zw5VWMoYUmQKRW0uicgpcxKuFC9jCASWhlnxKoawsVUkyyjLR74JFxmKzuxXO5C8oBlcPHyt39on5cWmZE5OqjZuP3Hf8vXt+T5tpKLLFyflTCFTbx8cqYhQJ8rZ0kytYvA979MQdubO+sCBDbj7etv/SllBGTpJwdN1ffKSM+VI+oox+G7LkDYuSUmLlximj1xx+E2YhJ+/ptcgCZOOREGXmjSSrnPBNyxzUl5wEcPF/ffgPnho0taWH/oxuG/BIHBvG8ztiip0ZEVSjIHxrSCV0RRBMrqRMcwqs7bZh9e20M9H1Bba5TTQeYtwREYn5BqdOj8GT3NVSzWjAlNFOYub4+ovrw5lGe5iCAy1Rh3JK6T5SpkWhTvl6CzN/EbD0Uu95ldTN2pmW/ahQzFcePqxEBtUO/Dq1yj/7sxp1U1zElRbRGaZTCvxHcq8j9JXZifCb5gQCY+4wek1pBsh6TNaoHoMBKactw3doWeWuPhP4IEbHQUH0CPY9t/vSY7YOTdF6rl35tlttbP3D+9wYg9ZxAkuuUCdTNfnjSgupze0JDnKm7SmypXP+fTKCEx0RI+Itgu9ooPGhkqh0FOvt4LneuFx2s6urEsn1ekML7PbZjY6t0y7DdZG5A62Adpbo3vin9pi3QnLf57QueYHIs9cxHqe+1eyV7/HtqixjD0tnjOLWJ3HKdVuJ/4Xwvo1Ap6G0gltwvFtIjfn0xjPsbqC0DaVs95KWA/zGIxWGxW9iXJ4o+8nX0rMKAGZCkQGhGVwWVLmNMviLppXuyuN4Zo/cYMfmAZxpMrDh2EHRiH6h873GvP+ABg2gEPBdwfYMjHioya+XX9Bd4MdbjXDaETOm8jegrAgLMZ+YbdIDD8y9vNt5yU23HmXdF9hbjSKT48w5vpjrMzu61sLXb3j4KdxEQt7CZENam3j6tCj/V+AN6uUUwwAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeTag = 'cz5dnl3';
export var bottomDiv = 'cz5dnlc';
export var breadcrumb = 'cz5dnl2';
export var closeTag = 'cz5dnl4';
export var dateDiv = 'cz5dnl6';
export var dateTagWrap = 'cz5dnl7';
export var dateText = 'cz5dnla';
export var day = 'cz5dnl9';
export var middleDiv = 'cz5dnl5';
export var month = 'cz5dnl8';
export var recruitingTag = 'cz5dnle';
export var subTitleClass = 'cz5dnlk';
export var tag = 'cz5dnlg';
export var tagList = 'cz5dnlf';
export var thumbnailDiv = 'cz5dnld';
export var titleBorder = 'cz5dnli';
export var titleClass = 'cz5dnlj';
export var titleContainer = 'cz5dnlh';
export var titleText = 'cz5dnlb';
export var topDiv = 'cz5dnl0';
export var topInformationDiv = 'cz5dnl1';
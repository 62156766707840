import React from 'react';

import SEO from '../../../utils/seo';
import { useWordPressPostSettings } from '../../../hooks/useWordPressPostSettings'
import SingleEvent from '../../../components/organisms/WordPress/SingleEvent';

const EventPage = (props: any) => {
    const slug = props.params.name;
    const { loading: articleLoading, data: articleData } = useWordPressPostSettings(slug);

    if (articleLoading) return <div></div>;
    const post = articleData.postBy;

    return (
        <>
            <SEO description={post.excerpt}
                lang={'ja'}
                meta={post.excerpt}
                title={`${post.title} | CRYINGTIGER`}
                url={""}
                img={post.featuredImage?.node.sourceUrl}
            />
            <SingleEvent post={post} />
        </>
    )
}

export default EventPage;
